body {
  margin: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: Sans;
}

#speed {
  margin: auto;
  font-size: 18rem;
}

#coords {
  text-align: center;
}
